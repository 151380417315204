  /* Media Query for Mobile Devices */
  @media (max-width: 480px) {

    .dashboard .dashboard_body .paymemt_history .payment_history_table table tr td input.editamount {
        width: 128px;
        font-size: 17px;
    }
    .dashboard .dashboard_head {
        padding: 20px 10px;
        background-color: var(--blue-color);
        font-size: 10px;
    }
    .dashboard .dashboard_body .paymemt_history .paymemt_history_heading h2 {
        /* font-size: 20px; */
        margin-bottom: 10px;
        font-weight: 600 !important;
    }

    .dashboard .dashboard_body .paymemt_history .payment_history_table {
        width: 100%;
        height: 45vh ;
    }
    .dashboard .dashboard_body .paymemt_history .payment_history_table  table{
        width: 150%;
    }
    .dashboard .dashboard_body .paymemt_history .payment_history_table table  th{
       padding:10px 20px!important;
    }
    .dashboard .dashboard_body .paymemt_history .payment_history_table table tr td {
        padding:10px 20px!important;
    }
    .dashboard .dashboard_body .paymemt_history .payment_information_accounting_office {
        padding: 10px 30px !important;
    }
    .dashboard .dashboard_body .payment_information form .formdetails {
        width: 100% !important;
    }
    .dashboard .dashboard_body .payment_information .paymemt_card {
        width: 100% !important;
    }
    .dashboard .dashboard_body .payment_informati20pxorm .card_info_tag hr {

        width: 320px !important;
        padding: 0px !important;
    }
    .dashboard .dashboard_body .payment_information form div {
        width: 100% !important;
    }
    .dashboard .dashboard_body .payment_information form .card_cvv_expiry {
        display: block;
    }
    #Cvv, #Expiry {
        width: 100%;
    }
    .dashboard .dashboard_body .payment_information .payment_information_heading .payment_information_heading_details {
        width: 100% !important;
    }
    .dashboard .dashboard_body .payment_information form button {
            width: 100% !important;
    }
    .dashboard .dashboard_body .payment_information .payment_information_heading {
            padding: 10px 00px;
    }
    .dashboard .dashboard_body .payment_information form .card_info_tag hr {
        padding: 0px;
    }

}



/* Media Query for Tablets Ipads portrait mode */
@media  (max-width: 1164px){
    .dashboard .dashboard_body {
       display: block;
    }
    .dashboard .dashboard_body .paymemt_history {
        width: 100%;
        height: auto;
        padding: 10px 30px;
    }
    .dashboard .dashboard_body .paymemt_history .payment_information_accounting_office {
        position: relative !important;
        width: 100%;
        border-bottom: 1px solid var(--border-color);
        margin-top: 0px;
    }
    .dashboard .dashboard_body .paymemt_history .payment_history_table {
        height: 55vh;
        margin-bottom: 20px;
    }
    .dashboard .dashboard_body .payment_information {
        width: 100%;
        height:auto;
        padding: 20px 30px;
        border-left: 1px solid var(--border-color);
    }

}

/* Media Query for Laptops and Desktops */
/* @media only screen and (min-width: 1300px){
	.dashboard .dashboard_body .paymemt_history .payment_history_table{height: auto !important;}
} */
/* Media Query for Large screens */






@media (max-width:1500px) {
    .dashboard .dashboard_head {
        padding: 20px 10px;
        background-color: var(--blue-color);
        font-size: 10px;
    }
    .dashboard .dashboard_body .paymemt_history .paymemt_history_heading h2 {
        /* font-size: 20px; */
        margin-bottom: 10px;
        font-weight: 600 !important;
    }

    .dashboard .dashboard_body .paymemt_history .payment_history_table {
        width: 100%;
        height: 55vh;
    }
    .dashboard .dashboard_body .paymemt_history .payment_history_table  table{
        width: 150%;
    }
    .dashboard .dashboard_body .paymemt_history .payment_history_table table  th{
       padding:10px !important;
    }
    .dashboard .dashboard_body .paymemt_history .payment_history_table table tr td {
        padding:10px !important;
    }
    .dashboard .dashboard_body .paymemt_history .payment_information_accounting_office {
        padding: 10px 30px !important;
    }
    .dashboard .dashboard_body .payment_information form .formdetails {
        width: 100% !important;
    }
    .dashboard .dashboard_body .payment_information .paymemt_card {
        width: 100% !important;
    }
    .dashboard .dashboard_body .payment_informati20pxorm .card_info_tag hr {

        width: 320px !important;
        padding: 0px !important;
    }
    .dashboard .dashboard_body .payment_information form div {
        width: 100% !important;
    }
    .dashboard .dashboard_body .payment_information form .card_cvv_expiry {
        display: block;
    }
    #Cvv, #Expiry {
        width: 100%;
    }
    .dashboard .dashboard_body .payment_information .payment_information_heading .payment_information_heading_details {
        width: 100% !important;
    }
    .dashboard .dashboard_body .payment_information form button {
            width: 100% !important;
    }
    .dashboard .dashboard_body .payment_information .payment_information_heading {
            padding: 10px 00px;
    }
    .dashboard .dashboard_body .payment_information form .card_info_tag hr {
        padding: 0px;
        width: 100%;
    }

}


/* Media Query for low resolution  Tablets, Ipads */

@media (max-width: 767px){
    .popupfix {
        width: 100%;
    }
    .dashboard .dashboard_body .paymemt_history .payment_history_table {
        width: 100%;
        height: 60vh  ;
        margin-bottom: 15px;
    }
    .dashboard .dashboard_body .paymemt_history .paymemt_history_heading {
        display:block;
        width: 100%;
    }
    .dashboard .dashboard_body .paymemt_history .payment_information_accounting_office{
        display: block;
        width: 100%;
    }

.dashboard .dashboard_body .paymemt_history .payment_information_accounting_office .card_vault{
    justify-content: start;
    text-align: left;
    width: 100%;
    margin-top: 20px;
}
.dashboard .dashboard_body .paymemt_history .payment_history_table table tr td input.editamount {
    width: 128px;
    font-size: 17px;
}
.dashboard .dashboard_body .paymemt_history .paymemt_history_heading div:first-child{
 display: block;

}
.dashboard .dashboard_body .paymemt_history .paymemt_history_heading div:first-child .subtotale{
    margin: 5px 0px;
}
td.dollar {
    display: flex;
    align-items: center;
    align-content: center;
}
}

@media (min-width: 481px) and (max-width: 1164px) {
    .dashboard .dashboard_body .paymemt_history .payment_history_table table  th{
        font-size: 18px;
    }
    .dashboard .dashboard_body .paymemt_history .payment_history_table table tr td {
        font-size: 18px;
    }
    .dashboard .dashboard_body .paymemt_history .payment_history_table{
        height:50vh;
/*         padding-bottom: 50px !important; */
    }
}

/*
 @media (max-width:450px) {
    .col-0.col-md-7.col-lg-7.total-row {
        display: none !important;
    }
    div#total-container{
        display: none;
    }
 } */

 /* Media Query for Mobile Devices */
 @media (max-width: 480px) {
    #popup .popup_discription{
        width:90%;
        padding: 10px;
        overflow: auto;
    }
    #popup .popup_discription p{
        margin: 10px 0px;
        padding: 0px;
    }
}