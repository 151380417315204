* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
/* .dollar{
    position: relative;
}
.symbol{
    margin: 0;
  position: absolute;
  top: 50%;
  left: 37px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
:root {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
}
.textright {
  text-align: right !important;
  padding-right: 18px;
}
.amout_width {
  width: 150px;
}
/* ...........Dashboard Css start ..........*/
.dashboard {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.dashboard .dashboard_head {
  padding: 10px 50px;
  background-color: #000000;
  display: flex;
  height: 75px;
  align-items: center;
  justify-content: space-between;
  color: var(--font-white-color);
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  z-index: 99;
}
.dashboard .dashboard_head .logo img {
  width: 100px;
}

/* Dashboard Boady css */
.subtotale {
  margin-left: 2rem;
}

/* paymemt_history start */
.dashboard .dashboard_body {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.dashboard .dashboard_body .paymemt_history {
  width: 70%;
  height: auto;
  padding: 10px 50px;
  position: relative;
}
.dashboard .dashboard_body .paymemt_history .paymemt_history_heading {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  margin-top: 70px;
}
.dashboard
  .dashboard_body
  .paymemt_history
  .paymemt_history_heading
  .subtotale {
  font-size: 14px;
}
.dashboard
  .dashboard_body
  .paymemt_history
  .paymemt_history_heading
  div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard .dashboard_body .paymemt_history .paymemt_history_heading h2 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 15px;
}

.dashboard .dashboard_body .paymemt_history .paymemt_history_heading p {
  color: var(--font-gray-color);
  font-size: 14px;
}

.dashboard .dashboard_body .paymemt_history .payment_history_table {
  margin-top: 30px;
  height: 300px;
  overflow-y: auto !important;
  width: 100%;
}
.dashboard
  .dashboard_body
  .paymemt_history
  .payment_history_table
  table
  tr
  td
  input {
  outline: none;
  padding: 7px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  /* font-size: 18px; */
  /* margin-left: 2px; */
  /* width: 132px; */
}
.dashboard
  .dashboard_body
  .paymemt_history
  .payment_history_table
  table
  tr
  td
  input.editamount {
  outline: none;
  /* padding: 0px 3px; */
  font-size: 18px;
  /* margin-left: 2px; */
  width: 132px;
}
.dashboard .dashboard_body .paymemt_history .payment_history_table table {
  width: 100%;
  text-align: center;
  font-size: 16px;
}
.dashboard .dashboard_body .paymemt_history .payment_history_table table tr th {
  padding: 20px 0px;
  color: var(--font-gray-color);
}
.dashboard
  .dashboard_body
  .paymemt_history
  .payment_history_table
  table
  tr
  th
  button {
  border: 1px solid rgba(58, 58, 209, 0.582);
  padding: 7px 10px;
  background-color: transparent;
  background-color: rgba(129, 129, 255, 0.178);
  cursor: pointer;
  color: var(--blue-color);
  font-weight: 600;
  border-radius: 5px;
  font-weight: 600;
}
.dashboard .dashboard_body .paymemt_history .payment_history_table table tr td {
  border-bottom: 1px solid var(--border-color);
  margin: 20px 0px;
  padding: 10px 0px;
  font-size: 18px;
}

.dashboard
  .dashboard_body
  .paymemt_history
  .payment_information_accounting_office,
.savecard-footer-container {
  position: fixed !important;
  left: 0px;
  bottom: 0%;
  padding: 10px 60px;
  bottom: 0px;
  width: 70%;
  border-top: 1px solid var(--border-color);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
.dashboard
  .dashboard_body
  .paymemt_history
  .payment_information_accounting_office
  .card_vault {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 50%;
}
.dashboard
  .dashboard_body
  .paymemt_history
  .payment_information_accounting_office
  .card_vault
  h3 {
  font-weight: 500;
  font-size: 20px;
}
.dashboard
  .dashboard_body
  .paymemt_history
  .payment_information_accounting_office
  .card_vault
  button {
  background-color: var(--blue-color);
  color: var(--font-white-color);
  outline: none;
  border: none;
  padding: 10px 15px;
  margin-left: 12px;
  border-radius: 20px;
  cursor: pointer;
  width: 100px;
}
.dashboard
  .dashboard_body
  .paymemt_history
  .payment_information_accounting_office
  h2,
.savecard-footer h2 {
  margin-bottom: 10px;
  font-size: 20px;
}

.dashboard
  .dashboard_body
  .paymemt_history
  .payment_information_accounting_office
  p,
.savecard-footer p {
  color: var(--font-gray-color);
  font-size: 14px;
}

table,
th,
td {
  border: 1px solid var(--border-color);
  border-collapse: collapse;
  border-top: none;
  border-left: none;
  border-right: none;
}

/* paymemt_history  End */

/* payment_information start */
.dashboard .dashboard_body .payment_information {
  width: 30%;
  height: 100vh;
  overflow-y: auto;
  padding: 10px 20px;
  border-left: 1px solid var(--border-color);
}
.dashboard .dashboard_body .payment_information .payment_information_heading {
  display: flex;
  align-items: center;
  justify-content: center;
  /*margin-top: 70px;*/
}
.dashboard
  .dashboard_body
  .payment_information
  .payment_information_heading
  .payment_information_heading_details {
  width: 384px;
}
.dashboard .dashboard_body .payment_information h2 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 20px;
  width: 100%;
}

.dashboard .dashboard_body .payment_information .paymemt_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /* width: 384px; */
  margin: 40px 0px;
}

.dashboard .dashboard_body .payment_information .paymemt_card img {
  width: 50px;
  cursor: pointer;
}

.dashboard .dashboard_body .payment_information form {
  display: flex;
  align-items: start;
  justify-content: center;
}

.dashboard .dashboard_body .payment_information form .formdetails {
  width: 384px;
}

/* lable and input common  css start */
.dashboard .dashboard_body .payment_information form div {
  width: 384px;
}
.dashboard .dashboard_body .payment_information form label {
  display: block;
  width: 100%;
  margin: 22px 0px;
  margin-bottom: 5px;
  font-size: 14px;
  text-align: left;
  /* margin-left: 100px; */
}

.dashboard .dashboard_body .payment_information form input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid var(--border-color);
  outline: none;
  height: 48px;
  border-radius: 5px;
  font-size: 16px;
}
#Cvv,
#Expiry {
  width: 180px;
}

.dashboard .dashboard_body .payment_information form input::placeholder {
  color: var(--border-color);
}

/* lable and input common  css End */

.dashboard .dashboard_body .payment_information form .card_info_tag {
  margin: 40px 0px;
  position: relative;
  margin-bottom: 80px;
}

.dashboard .dashboard_body .payment_information form .card_info_tag p {
  text-align: center;
  background-color: var(--font-white-color);
  padding: 10px;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dashboard .dashboard_body .payment_information form .card_info_tag hr {
  border-color: var(--border-color);
  border: 1px solid var(--border-color);
  position: absolute;
  border-bottom: none;
  top: 50%;
  padding: 0px 200px;
  width: 384px;
}

.dashboard .dashboard_body .payment_information form .card_cvv_expiry {
  width: 384px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard
  .dashboard_body
  .payment_information
  form
  .card_cvv_expiry
  .card_expiry {
  width: 45%;
}

.dashboard
  .dashboard_body
  .payment_information
  form
  .card_cvv_expiry
  .card_cvv {
  width: 45%;
}

.dashboard .dashboard_body .payment_information form button {
  width: 384px;
  background-color: var(--blue-color);
  margin: 40px 0px;
  padding: 15px;
  font-size: 16px;
  height: 56px;
  color: var(--font-white-color);
  outline: none;
  border: none;
  border-radius: 10px;
}

/* payment_information End */

/* popup css start */
.css-hz1bth-MuiDialog-container {
  z-index: 9999;
}
.popupfix button {
  width: 100%;
  background-color: #2d4691;
  border-radius: 12px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  height: 56px;
}

.popupfix button:hover {
  color: #fff;
  background-color: #192f70;
}

.popupfix h2 {
  color: #202022;
  font-size: 32px;
  padding-top: 20px;
}

.popupfix {
  text-align: center;
  padding: 40px 30px 30px !important;
  width: 480px;
}

.popupfix span {
  color: #72727a;
  font-size: 16px;
  padding-top: 10px;
}

.popupfix:nth-child(2) {
  padding-top: 0 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 36px;
}
/* popup css end */

.error {
  color: red;
}

.loader-container {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
}

#loader {
  border: 10px solid #2d3d8a40;
  border-radius: 50%;
  border-top: 10px solid #999999;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dashboard .dashboard_body .paymemt_history .payment_history_table {
  height: calc(71vh - 105px) !important;
}

.dashboard
  .dashboard_body
  .paymemt_history
  .payment_history_table
  table
  tr
  td
  input.editamount {
  border: none;
}
td.dollar div {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  width: 150px;
  margin: 0 auto;
}

.dashboard .dashboard_body .payment_information {
  padding-top: 100px;
}

#monerisCheckout
  iframe
  body.content_panel
  form
  #total-container
  div:first-child
  a {
  display: none !important;
}

.toggle-switch-parent-container {
  z-index: 100;
  right: 15%;
  top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-column-header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.toggle-switch-label {
  font-size: 1rem;
  font-weight: 600;
  margin-right: 1rem;
}

.toggle-switch-container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-switch-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-switch-container input:checked + .toggle-switch-slider {
  background-color: #2196f3;
}

.toggle-switch-container input:focus + .toggle-switch-slider {
  box-shadow: 0 0 1px #2196f3;
}

.toggle-switch-container input:checked + .toggle-switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.toggle-switch-slider.round {
  border-radius: 34px;
}

.toggle-switch-slider.round:before {
  border-radius: 50%;
}

#popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  background-color: rgba(19, 19, 19, 0.466);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999;
}

#popup .popup_discription {
  width: 450px;
  /* height: 400px; */
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#popup .popup_discription h2 {
  text-align: center;
  padding: 5px 0px;
  border-bottom: 1px solid rgb(204, 203, 203);
}
#popup .popup_discription p {
  margin: 10px 0px;
  color: rgb(126, 124, 124);
}
#popup .popup_discription .popup_check_terms_condition {
  display: flex;
  align-items: center;
}
#popup .popup_discription input {
  margin-right: 10px;
  cursor: pointer;
}
#popup .popup_discription hr {
  height: 5px;
  border: none;
  background-color: black;
  margin: 8px 0px;
}
#popup .popup_discription .popup_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#popup .popup_discription .popup_button button {
  padding: 10px 15px;
  border-radius: 20px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
#popup .popup_discription .popup_button button:first-child {
  border: 1px solid rgb(204, 203, 203);
}
#popup .popup_discription .popup_button button:first-child:hover {
  border: 1px solid rgb(204, 203, 203);
  transition: all 0.5s;
  background-color: red;
  color: white;
}
#popup .popup_discription .popup_button button:last-child {
  border: 1px solid rgb(204, 203, 203);
  background-color: #2d3d8a;
  color: white;
}
#popup .popup_discription .popup_button button:last-child:hover {
  border: 1px solid rgb(204, 203, 203);
  transition: all 0.5s;
  background-color: green;
  color: white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  position: relative;
  width: 40%;
  max-height: 80%;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto; /* Enables scrolling if content is larger than the modal */
  border-radius: 10px;
}

.loading-text {
  color: #999999;
  font-size: 1.25rem;
  margin-top: 0.75rem;
  font-weight: bold;
}

.api-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-save-modal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-save-modal-form label {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  display: block;
  width: 100%;
  margin: 1.5rem 0px;
  margin-bottom: 5px;
  font-size: 0.9rem;
  text-align: left;
}

.card-save-modal-form input {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid var(--border-color);
  outline: none;
  height: 3rem;
  border-radius: 5px;
  font-size: 1rem;
}

.card-information-header {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  margin-top: 3rem;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
}

.card-information-header-text {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  text-align: center;
  background-color: var(--font-white-color);
  padding: 10px;
  font-size: 0.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.save-card-button {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  width: 100%;
  background-color: var(--blue-color);
  margin: 2rem 0px;
  padding: 1rem;
  font-size: 1rem;
  height: 3.5rem;
  color: var(--font-white-color);
  outline: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.card-expiry-cvv-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card-expiry-cvv-container div {
  width: 47.5%;
}

.save-card-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.card_vault {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  width: fit-content !important;
  justify-content: space-between;
}

.card_vault div {
  margin-bottom: 0.5rem;
}

.payment-terms-separator {
  height: 0.25rem !important;
  background-color: black;
  margin-top: 1rem;
}

.popup_check_terms_condition {
  display: flex;
  align-items: center;
}

.popup_check_terms_condition input {
  margin-right: 0.5rem;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
}

.payment-terms-heading {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  text-align: center;
  padding: 5px 0px;
  border-bottom: 1px solid rgb(204, 203, 203);
}

.payment-terms-heading-margin {
  margin: 2.5rem 0;
}

.payment-terms-text {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  margin: 0.6rem 0px;
  color: rgb(126, 124, 124);
}

.button-pair-container {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto", sans-serif;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-pair-container .cancel-button {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  padding: 0.6rem 1rem;
  border-radius: 1rem;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid rgb(204, 203, 203);
}

.button-pair-container .cancel-button:hover {
  background-color: red;
  color: #ffffff;
}

.button-pair-container .save-button {
  --blue-color: #2d4691;
  --light-blue-color: #2d46917a;
  --font-white-color: white;
  --font-black-color: #202022;
  --font-gray-color: #72727a;
  --border-color: #cdcdcd;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  padding: 0.6rem 1rem;
  border-radius: 1rem;
  outline: none;
  cursor: pointer;
  border: 1px solid rgb(204, 203, 203);
  background-color: green;
  color: #ffffff;
}

.save-card-page {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 140px;
}

.save-card-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 99;
}

.header-logo-container {
  display: flex;
}

.header-logo-container .header-title-text {
  align-self: center;
  font-size: 1.5rem;
  margin-left: 2.5rem;
}

.bg-white {
  background-color: #ffffff;
}
